import products from '../../../sharedTypes/products';
import constants from '../../constants';

const { states } = constants;

export default {
  product: products.PAYMENTS,
  state: states.PAYMENTS_SELECTING_AUTH,
  frozenConfig: {
    payments: {
      authOptions: ['device', 'app', 'sms', 'coordinates'],
    },
    widget: {
      entityIdSelected: 'cl_banco_estado', // optional
    },
  },
};
